import { User } from '@supabase/supabase-js'
import { getTime } from 'date-fns'
import { Profile } from '~/lib/utils'

export const generateIntercomScripts = (user: User, profile?: Profile) => {
  if (typeof window !== 'undefined') {
    let intercomSettingScript = document.createElement('script')
    intercomSettingScript.innerHTML = `
        window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "y82074rt",
        user_id: ${JSON.stringify(user.id)},
        email: ${JSON.stringify(user.email)},
        created_at: ${getTime(new Date(user.created_at)) / 1000},
        "last_signed_in_at": ${getTime(new Date(user.last_sign_in_at!)) / 1000},
        name: ${
          profile?.first_name && profile?.last_name
            ? JSON.stringify(profile.first_name + ' ' + profile.last_name)
            : null
        },
        "job_role": ${
          profile?.job_role ? JSON.stringify(profile?.job_role) : null
        },
      };
      `
    let intercomScript = document.createElement('script')
    intercomScript.innerHTML = `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/y82074rt';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      `
    document.head.append(intercomSettingScript)
    document.head.append(intercomScript)
  }
}

export const updateIntercomUser = (profile: Profile) => {
  if (typeof window !== 'undefined' && window.Intercom) {
    let intercom = window.Intercom
    intercom('update', {
      name: profile.first_name + ' ' + profile.last_name,
      job_role: profile.job_role,
    })
  }
}
